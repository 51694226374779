import React from 'react'

function Home() {
  const handleRedirect = () => {
    window.location.href = '/lessonOrder'; // Replace with your desired URL
  };

  return (
    <div className="home-container">
      {/* Головний контент */}
      <div className="welcome-section">
        <h1>Ласкаво просимо на наш освітній портал</h1>
        <p>
          Ми створили цей сайт для того, щоб зробити якісну освіту доступною для кожного.
          Наша мета – надати освітні послуги за низькими цінами без втрат для репетиторів.
        </p>
      </div>

      {/* Блоки з інформацією */}
      <div className="features-section">
        <div className="feature-card">
          <h2>Низькі ціни</h2>
          <p>
            Ми прагнемо забезпечити доступ для всіх до знань для всіх охочих. Наші ціни настільки низькі, 
            що навчання стає доступним кожному, незалежно від фінансового становища.
          </p>
        </div>
        <div className="feature-card">
          <h2>Ціна за заняття</h2>
          <p>для одного - 250 грн</p>
          <p>для двох - 200 грн (20%)</p>
          <p>для трьох - 175 грн (30%)</p>
          <p>для чотирьох - 150 грн (40%)</p>
          <p>для п’ятьох - 125 грн (50%)</p>
        </div>
        <div className="feature-card">
          <h2>Висока якість освіти</h2>
          <p>
            Незважаючи на низькі ціни, ми гарантуємо високу якість навчання. Наші репетитори – 
            професіонали у своїй галузі, які готові поділитися своїми знаннями та досвідом.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Home