import './index.css'
import './conponents/User/profile.css'
// import './conponents/User/User.css'
import './conponents/User/loginRagi.css'
import './conponents/Order/orderCard.css'
import './conponents/Group/group.css'
import './conponents/AddLessons/AddLessons.css'
import './conponents/Home/home.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProfilAuthReg from "./conponents/User/ProfilAuthReg";
import Study from "./conponents/Study/study";
import Order from "./conponents/Order/order";
import Home from "./conponents/Home/home";
import History from "./conponents/History/history";
import Group from "./conponents/Group/group";
import Lesson from './conponents/Study/Lesson';
import Tema from './conponents/Study/Tema';
import AddLesson from './conponents/AddLessons/AddLessons'
import { useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from './firebaseConfig'
import SoloLesson from './conponents/SoloLesson/SoloLesson'
import BuyLesson from './conponents/BuyLesson/BuyLesson'



function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(true); // Змінити на реальний стан авторизації

  const handleLogout = () => {
    // Логіка для виходу користувача
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    setIsAuthenticated(false);
  };

  useEffect(()=>{
    const unSub = onAuthStateChanged(auth, (user)=>{
      if(user == null){
        setIsAuthenticated(false);
      }else{
        setIsAuthenticated(true);
      }
      console.log(user)
    })

    // return () =>{
    //   unSub()
    // }
  })


  return (
    <div className="App">
      <header>
        <nav class="navbar">
          <div class="container">
              <div class="nav-left">
                  <a href="/home" class="brand">Easlern</a>
                  <a href="/lessons" class="nav-link">Предмети</a>
                  <a href="/lessonOrder" class="nav-link">Записатись на заняття</a>
              </div>
              <div class="nav-right">
                  {/* <a href="/history" class="nav-link">History</a> */}
                  {isAuthenticated ? (
                  <a href="/" className="nav-link" onClick={handleLogout}>Вихід</a>
                  ) : (
                  <a href="/user" className="nav-link">Увійти</a>
                  )}
                  {isAuthenticated ? (
                  <a href="/user" className="nav-link">Профіль</a>
                  ) : (
                    <></>
                  )}
              </div>
          </div>
        </nav>
      </header>
      <main className="main">
        <Router>
          <Routes>
            <Route path="/" element ={ <Home/> }/>
            <Route path="/user" element ={<ProfilAuthReg />}/>
            <Route path="/lessons" element ={<Study/>}/>
            <Route path="/study/:param" element={<Tema />} />
            <Route path="/study/:param1/:param2" element={<Lesson />} />
            <Route path="/order/:param1/:param2/:param3" element ={<Order/>}/>
            <Route path="/home" element ={<Home/>}/>
            <Route path="/addLesson" element ={<AddLesson/>}/>
            <Route path="/lessonOrder" element ={<BuyLesson/>}/>
            <Route path="/history" element ={<History/>}/>
            <Route path="/group/:param1/:param2/:param3" element ={<Group/>}/>
            <Route path="/solo/:param1/:param2/:param3" element ={<SoloLesson/>}/>
          </Routes>
        </Router>
      </main>

      <footer class="footer">
        <div class="footer-content">
          <p>© 2024 Easlern. Всі права захищені.</p>
          {/* <ul class="footer-links">
            <li><a href="#about">Про нас</a></li>
            <li><a href="#terms">Умови використання</a></li>
            <li><a href="#contact">Контакти</a></li>
          </ul> */}
        </div>
      </footer>
    </div>
  );
}

export default App;
